<template>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-12'>

              <div class='p-0 h-full flex flex-column'>

                <div class='grid'>

                <div class='col-12 lg:col-9'>
                <h5 class='mb-0 mt-3'><i class="pi pi-check mr-2"></i> Proje Listesi</h5>
                </div>

                <div class='col-12 lg:col-3 text-right'>

                <a href='#/Project/Add'>
				<Button label='Proje Ekle' icon='pi pi-plus' class='p-button-danger'></Button>	
                </a>	

                </div>

                </div>

                <DataTable
                    :value='dataList'
                    :paginator='true'
                    class='p-datatable-gridlines'
                    :rows='5'
                    dataKey='id'
                    :rowHover='true'
                    v-model:filters='filters'
                    filterDisplay='menu'
                    :loading='loadingIndicator'
                    :filters='filters'
                    responsiveLayout='scroll'
                    :globalFilterFields="['name','cityName','townName','phone','email']">

                    <template #header>
                        <div class='flex justify-content-between flex-column sm:flex-row'>
                            <Button type='button' icon='pi pi-filter-slash' label='Temizle'
                                    class='p-button-outlined mb-2' @click='clearFilter()' />
                            <span class='p-input-icon-left mb-2'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama' style='width: 100%' />
                            </span>
                        </div>
                    </template>

                    <template #empty>
                        Veri bulunamadı.
                    </template>

                    <template #loading>
                        Proje listesi yükleniyor. Lütfen bekleyin.
                    </template>


                    <Column field='projectLogo' header='Logo' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='{data}'>
                            <img v-if='data.projectLogo != null && data.projectLogo != ""' :src="'http://image.a1catering.com.tr/'+data.projectLogo" width='80'/>
                        </template>
                    </Column>

                    <Column field='name' header='Proje Tanımı' filterField='name' :showFilterMatchModes='false'>
                    </Column>

                    <Column field='cityName' header='Şehir' filterField='cityName' :showFilterMatchModes='false'>
                    </Column>

                    <Column field='townName' header='İlçe' filterField='townName' :showFilterMatchModes='false'>
                    </Column>

                    <Column field='phone' header='Telefon' filterField='phone' :showFilterMatchModes='false'>
                    </Column>

                    <Column field='email' header='E-Posta' filterField='email' :showFilterMatchModes='false'>
                    </Column>

                    <Column header='Oluşturma Tarihi' filterField='insertDate' dataType='date'>
                        <template #body='{data}'>
                            {{ formatDate(data.insertDate) }}
                        </template>
                    </Column>

                    <Column field='verified' header='Durum' dataType='boolean' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isActive, 'text-pink-500 pi-times-circle': !data.isActive}"></i>
                        </template>
                    </Column>

                    <Column header='Düzenle' bodyClass='text-center' style='width:6.5rem' :exportable='false'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-primary'
                                    @click='edit(slotProps.data)' />
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import ProjectService from '../../services/projectService';
import { checkUserRole } from '../common/commonConstantFunctions';

export default {
    data() {
        return {
            dataList: [],
            filters: null,
            loadingIndicator: true,
            expandedRows: [],
        };
    },
    _projectService: null,
    created() {
        checkUserRole(this, "Admin");
        this._projectService = new ProjectService();
        this.initFilters();
        this.loadingIndicator = true;
    },
    async mounted() {
        const response = await this._projectService.getAllProjects();
        this.dataList = response.data;
        this.loadingIndicator = false;
    },
    methods: {
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },
        edit(item) {
            this.$router.push({ name: 'editProject', params: { projectId: item.id } });
        },
    },
};
</script>

<style scoped lang='scss'>

</style>
